<template>
    <div>
        <v-overlay
            :value="updatingTotals"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :headers="headers"
            :items="Salesitems"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            disable-pagination
            disable-sort
            hide-default-header
            hide-default-footer
            item-key="index"
            single-select
        >
            <template v-if="Contract__salestype_id == 2" v-slot:top>
                <div class="dense-inputs pb-3">
                    <v-layout row>
                        <v-flex xs6 sm6>
                            <div class="d-flex">
                                <span>
                                    <v-radio-group
                                        class="mt-2"
                                        dense
                                        hide-details
                                        row
                                        v-model="Contract__commission_by_unit"
                                        @change="commissionTypeChanged"
                                    >
                                        <v-radio :label="$t('message.commPct')" :value="0"/>
                                        <v-radio :label="$t('message.commUnit')" :value="1"/>
                                    </v-radio-group>
                                </span>
                                <span class="pl-3">
                                    <v-text-field
                                        :value="Contract__margin"
                                        clearable
                                        dense
                                        hide-details
                                        outlined
                                        style="width: 125px !important"
                                        suffix="%"
                                        type="number"
                                        v-if="Contract__commission_by_unit == 0"
                                        @change="Contract__margin = $event"
                                    >
                                    </v-text-field>
                                    <v-text-field
                                        :value="Contract__unit_commission"
                                        clearable
                                        dense
                                        hide-details
                                        outlined
                                        style="width: 125px !important"
                                        type="number"
                                        v-if="Contract__commission_by_unit == 1"
                                        @change="Contract__unit_commission = $event"
                                    >
                                    </v-text-field>
                                </span>
                                <span class="pl-5">
                                    <v-switch
                                        :label="$t('message.perLine')"
                                        class="mt-2"
                                        dense
                                        hide-details
                                        true-value="1"
                                        v-model="Contract__commission_per_line"
                                    >
                                    </v-switch>
                                </span>
                            </div>
                        </v-flex>
                    </v-layout>
                </div>
            </template>
            <template v-slot:header="{ props: { headers } }">
                <tr v-if="Contract__salestype_id != 2 && updateMode">
                    <th class="grey lighten-3 pa-1 text-left width-1-pct">{{ headers[0].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-6-pct">{{ headers[1].text}}</th>
                    <th class="grey lighten-3 pa-1 text-left width-10-pct">{{ headers[2].text}}</th>
                    <th class="grey lighten-3 pa-1 text-left" style="width: 400px !important;">{{ headers[3].text}}</th>
                    <th class="grey lighten-3 pa-1 text-left width-18-pct">{{ headers[4].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-6-pct">{{ headers[5].text}}</th>
                    <th class="grey lighten-3 pa-1 text-left width-8-pct">{{ headers[6].text}}</th>
                    <th class="grey lighten-3 pa-1 text-left width-6-pct">{{ headers[7].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[8].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[9].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left text-no-wrap">{{ headers[10].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left text-no-wrap">{{ headers[11].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[12].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-1-pct">{{ $t('message.actions') }}</th>
                </tr>
                <tr v-else-if="Contract__salestype_id != 2 && updateMode == false">
                    <th class="grey lighten-3 pa-1 text-left width-1-pct">{{ headers[0].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-6-pct">{{ headers[1].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-10-pct">{{ headers[2].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left" style="width: 400px !important;">{{ headers[3].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-18-pct">{{ headers[4].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-6-pct">{{ headers[5].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-8-pct">{{ headers[6].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-6-pct">{{ headers[7].text  }}</th>
                    <th class="grey lighten-3 pa-1  text-left">{{ headers[8].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left text-no-wrap">{{ headers[9].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left text-no-wrap">{{ headers[10].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[11].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-1-pct">{{ $t('message.actions') }}</th>
                </tr>
                <tr v-else>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct">{{ headers[0].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-8-pct">{{ headers[1].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-10-pct">{{ headers[2].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left" style="width: 400px !important;">{{ headers[3].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-18-pct">{{ headers[4].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-8-pct">{{ headers[5].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-10-pct">{{ headers[6].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left">{{ headers[7].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-8-pct">{{ headers[8].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-5-pct" v-if="Contract__commission_by_unit == 0">{{ headers[9].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-5-pct" v-else-if="Contract__commission_by_unit == 1">{{ headers[9].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct">{{ $t('message.actions') }}</th>
                </tr>
            </template>
            <template v-slot:item.lineNumber="{ item }">
                <div class="text-center mt-2 font-16px">{{ item.lineNumber }}</div>
            </template>
            <template v-slot:item.Salesitem.certification_id="{item}">
                <v-select
                    :id="item.index + 'certification'"
                    :items="allCertifications"
                    :value="item.Salesitem.certification_id"
                    class="text-right custom pa-1 bg-lightgrey"
                    dense
                    hide-details="auto"
                    item-value="Certification.id"
                    item-text="Certification.title"
                    v-if="item.type == 'default'"
                    @change="updateSalesItemRowHandle( item.index, 'Salesitem.certification_id',$event)"
                />
            </template>
            <template v-slot:item.Salesdescription.species_id="{item}">
                <v-autocomplete
                    :id="item.index + 'species'"
                    :items=allSpecies
                    :placeholder="$t('message.startTypingToSearch')"
                    :value="item.Salesdescription.species_id"
                    clearable
                    class="custom pa-1 bg-lightgrey"
                    dense
                    hide-details="auto"
                    item-value="Species.id"
                    item-text="Species.title"
                    v-if="item.type == 'default'"
                    @input="updateSalesItemRowHandle( item.index, 'Salesdescription.species_id',$event)"
                />
            </template>
            <template v-slot:item.Salesdescription.spec_id="{ item }">
                <div v-if="item.type == 'default'">
                    <div class="d-flex flex-row align-center">
                        <div style="width: 50px" class="font-16px">{{ $t('message.product') + ":" }}</div>
                        <v-autocomplete
                            :id="item.index + 'product'"
                            :items=allProductGroups
                            :placeholder="item.Salesitem.productgroup_id ? '' : $t('message.required')"
                            :value="item.Salesitem.productgroup_id"
                            class="custom pa-1 bg-lightgrey"
                            clearable
                            dense
                            hide-details="auto"
                            item-text="ProductGroup.title"
                            item-value="ProductGroup.id"
                            @change="updateSalesItemRowHandle( item.index, 'Salesitem.productgroup_id',$event)"
                        />
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="width: 50px !important;" class="font-16px">{{ $t('message.spec') + ":" }}</div>
                        <v-combobox
                            :id="item.index + 'spec'"
                            :items="allCleanedSpecs"
                            :placeholder="$t('message.selectOptionOrFreeText')"
                            :value="item.Spec.title"
                            class="custom pa-1 bg-lightgrey"
                            clearable
                            dense
                            hide-details="auto"
                            hide-no-data
                            item-value="Spec.id"
                            item-text="Spec.title"
                            return-object
                            v-if="item.type == 'default'"
                            @focus="currentRowIndex = item.index"
                            @keyup="showUpdateButton('itemSpec' + item.index)"
                            @change="updateSalesItemRowHandle( item.index, 'Salesdescription.spec_id',$event)"
                        />
                        <v-btn icon small :ref="'itemSpec' + item.index" style="display: none" class="ml-3">
                            <v-icon class="green--text text--darken-1 font-weight-bold">check</v-icon>
                        </v-btn>
                    </div>
                    <div class="d-flex flex-row align-center" v-if="Contract__productgroup_id != 15">
                        <div style="width: 50px !important;" class="font-16px">{{ $t('message.mc') + ":" }}</div>
                        <v-select
                            :id="item.index + 'mc'"
                            :items="allMoistureContent"
                            :value="item.Salesitem.mc_id"
                            class="custom pa-1 bg-lightgrey"
                            clearable
                            dense
                            hide-details="auto"
                            item-text="Mc.title"
                            item-value="Mc.id"
                            @focus="currentRowIndex = item.index"
                            @change="updateSalesItemRowHandle( item.index, 'Salesitem.mc_id',$event)"
                        />
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="width: 50px !important;" class="font-16px">{{ $t('message.grade') + ":" }}</div>
                        <v-autocomplete
                            :id="item.index + 'grade'"
                            :items="allGrades"
                            :placeholder="$t('message.startTypingToSearch')"
                            :value="item.Salesitem.grade_id"
                            class="custom pa-1 bg-lightgrey"
                            clearable
                            dense
                            hide-details="auto"
                            hide-no-data
                            item-text="Grade.title"
                            item-value="Grade.id"
                            return-object
                            v-if="item.type == 'default'"
                            @focus="currentRowIndex = item.index"
                            @change="updateSalesItemRowHandle( item.index, 'Salesitem.grade_id',$event)"
                        >
                            <template v-slot:selection="{ item }">
                                <div class="text-wrap" style="width: 100%">{{ item.Grade.title }}</div>
                            </template>
                        </v-autocomplete>
                    </div>
                    <div class="d-flex flex-row align-center" style="min-height: 35px; width: 100%;">
                        <div class="font-16px">{{ $t('message.specificationAsShownContract') + ": " + getUiDescription(item) }}</div>
                    </div>
                </div>
                <div v-if="item.type == 'othercharge' && item.Salesitem.chargeto == 0">
                    <v-text-field
                        :id="item.index + 'size'"
                        :placeholder="$t('message.otherCharges')"
                        :value="item.Salesitem.size"
                        class="custom pa-1 force-text-left bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        @change="updateSalesItemRowHandle( item.index, 'Salesitem.size',$event)"
                    />
                </div>
                <div v-if="item.type == 'othercharge' && item.Salesitem.chargeto == 1">
                    {{ $t('message.po') + ': ' + item.Salesitem.size }}
                </div>
            </template>
            <template v-slot:item.Salesitem.size_id="{ item }">
                <div v-if="item.type == 'default'">
                    <div class="d-flex flex-row align-center">
                        <div style="width: 65px !important;" class="font-16px">{{ $t('message.thickness') + ":" }}</div>
                        <v-autocomplete
                            :id="item.index + 'thickness'"
                            :label="item.Salesitem.thickness_id == null && item.Salesitem.thickness != '' ? item.Salesitem.thickness : ''"
                            :value="item.Salesitem.thickness_id"
                            :items="filteredThickness"
                            class="custom pa-1 bg-lightgrey"
                            clearable
                            dense
                            hide-details="auto"
                            item-text="Thickness.title"
                            item-value="Thickness.id"
                            @focus="currentRowIndex = item.index"
                            @change="updateSalesItemRowHandle( item.index, 'Salesitem.thickness_id',$event)"
                        />
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="width: 65px !important;" class="font-16px">{{ $t('message.width') + ":" }}</div>
                        <v-text-field
                            :id="item.index + 'width'"
                            :value="item.Salesitem.width"
                            class="custom pa-1 bg-lightgrey force-text-left"
                            clearable
                            dense
                            hide-details="auto"
                            @keyup="showUpdateButton('itemWidth' + item.index)"
                            @change="updateSalesItemRowHandle( item.index, 'Salesitem.width',$event)"
                        />
                        <v-btn icon small :ref="'itemWidth' + item.index" style="display: none" class="ml-3">
                            <v-icon class="green--text text--darken-1 font-weight-bold">check</v-icon>
                        </v-btn>
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="width: 65px !important;" class="font-16px">{{ $t('message.length') + ":" }}</div>
                        <v-text-field
                            :id="item.index + 'length'"
                            :value="item.Salesitem.length"
                            class="custom pa-1 bg-lightgrey force-text-left"
                            clearable
                            dense
                            hide-details="auto"
                            @keyup="showUpdateButton('itemLength' + item.index)"
                            @change="updateSalesItemRowHandle( item.index, 'Salesitem.length',$event)"
                        />
                        <v-btn icon small :ref="'itemLength' + item.index" style="display: none" class="ml-3">
                            <v-icon class="green--text text--darken-1 font-weight-bold">check</v-icon>
                        </v-btn>
                    </div>
                    <div class="d-flex flex-column justify-space-between" style="min-height: 35px; width: 100%;">
                        <div class="font-16px">{{ $t('message.sizeAsShownContract') + ": " + (item.Salesitem.size ? item.Salesitem.size : '') }}</div>
                        <div class="mt-3 font-16px">
                            {{ $t('message.pleaseClick') }}
                            <v-icon class='green--text text--darken-1 font-weight-bold mx-1'>check</v-icon>
                            {{ $t('message.toUpdateValue') }}
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:item.Salesitem.itemqty="{ item }">
                <div style="height: 100%" v-if="item.type == 'default'">
                    <v-text-field
                        :id="item.index + 'itemqty'"
                        :value="item.Salesitem.itemqty"
                        class="custom pa-1 bg-lightgrey"
                        dense
                        hide-details="auto"
                        min="0"
                        type="number"
                        @change="updateSalesItemRowHandle( item.index, 'Salesitem.itemqty',$event)"
                    />
                    <div class="font-weight-bold font-md d-flex flex-column justify-end" style="height: 34px; font-size: 1.10em">{{ $t('message.unit') }}</div>
                    <v-select
                        :id="item.index + 'uofm'"
                        :items="uofmQty"
                        :value="item.Salesitem.measurement_id"
                        class="text-right custom pa-1 bg-lightgrey"
                        dense
                        hide-details="auto"
                        item-value="Measurement.id"
                        item-text="Measurement.title"
                        reverse
                        @change="updateSalesItemRowHandle( item.index, 'Salesitem.measurement_id',$event)"
                    />
                </div>
            </template>
            <template v-slot:item.Salesitem.costprice="{ item }" v-if="[1,3,5].includes(Contract__salestype_id)">
                <v-text-field
                    :value="item.Salesitem.costprice"
                    class="custom pa-1 bg-lightgrey"
                    dense
                    hide-details="auto"
                    type="number"
                    v-if="item.type == 'default'"
                    @change="updateSalesItemRowHandle( item.index, 'Salesitem.costprice',$event)"
                />
                <template v-if="Contract__salestype_id == 5 && item.type == 'default'">
                    <div class="font-16px d-flex flex-row align-center justify-content-end text-end red--text darken-1" style="min-height: 33px !important">{{ $t('message.importTransportEtc') + ':' }}</div>
                    <div class="font-16px d-flex flex-row align-center justify-content-end" style="min-height: 33px !important">{{ $t('message.cfr') + ':' }}</div>
                </template>
                <div class="text-light mr-4 mt-2 font-16px" v-if="item.type == 'othercharge' && item.Salesitem.chargeto == 1">
                    {{ formatThisNumber(item.Salesitem.costprice,currencyFormatPrecision) }}
                </div>
            </template>
            <template v-slot:item.Salesitem.unitprice="{ item }">
                <v-text-field
                    :value="item.Salesitem.unitprice"
                    class="custom pa-1 bg-lightgrey"
                    dense
                    hide-details="auto"
                    type="number"
                    min="0"
                    v-if="item.type == 'default'"
                    @change="updateSalesItemRowHandle( item.index, 'Salesitem.unitprice',$event)"
                />
                <template v-if="Contract__salestype_id == 5 && item.type == 'default'">
                    <v-text-field
                        :value="item.Salesitem.import_transport_price"
                        class="custom pa-1 bg-lightgrey border-red"
                        dense
                        hide-details="auto"
                        type="number"
                        min="0"
                        v-if="item.type == 'default'"
                        @change="updateSalesItemRowHandle( item.index, 'Salesitem.import_transport_price',$event)"
                    />
                    <div class="font-16px d-flex flex-row align-center justify-content-end mt-1 mr-4" style="min-height: 33px !important">{{ item.Salesitem.cfr_price }}</div>
                </template>
            </template>
            <template v-slot:item.Salesitem.amount="{ item }">
                <div class="text-light mt-2 font-16px" v-if="item.type == 'default'">{{ formatThisNumber(item.Salesitem.amount,currencyFormatPrecision) }}</div>
                <div v-else-if="item.type == 'othercharge' && item.Salesitem.chargeto == 0" class="d-inline-flex" style="margin-right: -20px">
                    <v-icon small class="mb-3 red--text" v-if="item.type == 'credit'">mdi-minus</v-icon>
                    <v-text-field
                        :value="item.Salesitem.amount"
                        class="custom pa-1 bg-lightgrey force-text-left"
                        dense
                        hide-details="auto"
                        type="number"
                        @change="updateSalesItemRowHandle( item.index, 'Salesitem.amount',$event)"
                    ></v-text-field>
                </div>
            </template>
            <template v-slot:item.Salesitem.poqty="{ item }">
                <div class="text-light mt-2 font-16px" v-if="item.type == 'default'">{{ formatThisNumber(item.Salesitem.poqty, uofmPrecision(item.Salesitem.pomeasurement_id)) }}</div>
            </template>
            <template v-slot:item.Salesitem.unitmargin="{ item }">
                <div class="text-light mt-2 font-16px" v-if="item.type == 'default'">{{ formatThisNumber(item.Salesitem.unitmargin, currencyFormatPrecision) }}</div>
            </template>
            <template v-slot:item.Salesitem.margin="{ item }">
                <div class="text-light mt-2 font-16px" v-if="item.type == 'default'">{{ formatThisNumber(item.Salesitem.margin, currencyFormatPrecision) }}</div>
            </template>
            <template v-slot:item.marginPct="{ item }">
                <div class="text-light mt-2 font-16px" v-if="item.type == 'default'">{{ marginPct(item.Salesitem.unitprice, item.Salesitem.costprice, item.Salesitem.import_transport_price) }}</div>
            </template>
            <template v-slot:item.Salesitem.comm_pct="{ item }">
                <v-text-field
                    :readonly="Contract__commission_per_line == 0"
                    :value="item.Salesitem.comm_pct"
                    class="custom bg-lightgrey"
                    dense
                    hide-details="auto"
                    suffix="%"
                    type="number"
                    v-if="item.type == 'default'"
                    @change="updateSalesItemRowHandle( item.index, 'Salesitem.comm_pct',$event)"
                ></v-text-field>
            </template>
            <template v-slot:item.Salesitem.comm_unit="{ item }">
                <v-text-field
                    :readonly="Contract__commission_per_line == 0"
                    :suffix="'/' + item.Measurement.title"
                    :value="item.Salesitem.comm_unit"
                    class="custom bg-lightgrey"
                    dense
                    hide-details="auto"
                    type="number"
                    v-if="item.type == 'default'"
                    @change="updateSalesItemRowHandle( item.index, 'Salesitem.comm_unit',$event)"
                ></v-text-field>
            </template>
            <template v-slot:item.Salesitem.commission="{ item }">
                <div class="mt-2 font-16px" v-if="item.type == 'default'">{{ formatThisNumber(item.Salesitem.commission, currencyFormatPrecision) }}</div>
            </template>
            <template v-slot:item.index="{ item }">
                <div class="ml-2" v-if="item.Salesitem.chargeto != 1">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="deleteItem(item.index, item.Salesitem.id)" v-on="on">
                                <v-icon color="grey">delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.deleteThisItem') }}</span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:footer>
                <div class="pt-0 d-flex justify-space-between">
                    <div class="col-3 col-lg-3 col-md-3 pt-5 px-0 d-flex justify-start">
                        <v-btn
                            @click="addSalesItemRow(someContractData)"
                            color="success"
                            small
                        >{{ Salesitems.length == 0 ? $t('message.addItem') : $t('message.addMore') }}
                        </v-btn>
                        <v-btn
                            @click="addOtherChargesItemRow(someContractData)"
                            class="ml-2"
                            color="success"
                            small
                        >{{ $t('message.addOtherCharges') }}
                        </v-btn>
                        <v-btn
                            @click="addCreditSalesItemRow(someContractData)"
                            class="ml-2"
                            color="success"
                            small
                            v-if="false"
                        >{{ Salesitems.length == 0 ? $t('message.addItem') : $t('message.addCredit') }}
                        </v-btn>
                        <v-btn
                            @click.stop="specNotesDialog = true"
                            class="ml-2"
                            color="success"
                            small
                            v-if="false"
                        >{{ $t('message.addSpecificationNotes') }}
                        </v-btn>
                        <v-btn
                            :loading="resettingSalesitems"
                            class="ml-2"
                            color="default"
                            small
                            v-if="updateMode == false"
                            @click="resetSpecifications(statePrefix)"
                        ><v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                        </v-btn>
                    </div>
                    <div class="dense-inputs col-9 col-lg-9 col-md-9 pa-0">
                        <v-row justify="end">
                            <v-col cols="2" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-sm font-weight-black">{{ $t('message.numberOfContainers') }}</div>
                                <v-row no-gutters class="justify-end">
                                    <v-col cols="3" class="pa-0 ma-0" v-if="[4,7].indexOf(Contract__containersize_id) == -1">
                                        <v-text-field
                                            :value="Contract__containercount"
                                            class="custom pa-0 mt-0 bg-lightgrey"
                                            dense
                                            hide-details
                                            min="1"
                                            type="number"
                                            @change="Contract__containercount = $event"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="1" class="pa-0 ma-0" v-if="[4,7].indexOf(Contract__containersize_id) == -1">
                                        <v-icon small class="mt-1">mdi-close</v-icon>
                                    </v-col>
                                    <v-col :cols="[4,7].indexOf(Contract__containersize_id) == -1 ? 5 : 12" class="pa-0 ma-0">
                                        <v-container class="ma-0 pa-0">
                                            <v-row no-gutters>
                                                <v-col>
                                                    <v-select
                                                        :items="containerSizes"
                                                        class="custom py-0 mt-0 bg-lightgrey"
                                                        dense
                                                        hide-details
                                                        item-text="Containersize.title"
                                                        item-value="Containersize.id"
                                                        reverse
                                                        v-model="Contract__containersize_id"
                                                    >
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="2" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-sm font-weight-black">{{ $t('message.totalQty') }}</div>
                                <div class="text-right font-weight-black d-flex flex-column">
                                    <span v-for="(total,index) in itemsTotalQty" :class="itemsTotalQty.length > 1 && index == 0  ? 'pt-1' : ''">
                                        {{ formatThisNumber(total.Total.qty,uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                    </span>
                                </div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-weight-black">{{ $t('message.currency') }}</div>
                                <div class="pl-5">
                                    <v-select
                                        :items="allCurrencies"
                                        class="custom py-0 mt-0 bg-lightgrey"
                                        hide-details
                                        item-text="Currency.code"
                                        item-value="Currency.id"
                                        reverse
                                        v-model="Contract__currency_id"
                                    >
                                    </v-select>
                                </div>
                            </v-col>
                            <template v-if="[1,3,5].includes(Contract__salestype_id)">
                                <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                    <div class="text-right font-weight-black">{{ $t('message.totalCost') }}</div>
                                    <div class="text-right font-weight-black">{{ formatThisNumber(itemsTotalCost,currencyFormatPrecision) }}</div>
                                </v-col>
                                <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                    <div class="text-right font-weight-black">{{ $t('message.totalAmount') }}</div>
                                    <div class="text-right font-weight-black">{{ formatThisNumber(itemsTotalAmount,currencyFormatPrecision) }}</div>
                                </v-col>
                                <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pb-0">
                                    <div class="text-right font-weight-black">{{ $t('message.totalMarginFob') }}</div>
                                    <div class="text-right font-weight-black">{{ formatThisNumber(itemsTotalMargin,currencyFormatPrecision) }}</div>
                                </v-col>
                            </template>
                            <template v-else>
                                <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                    <div class="font-weight-black">{{ $t('message.totalAmount') }}</div>
                                    <div class="font-weight-black">{{ formatThisNumber(itemsTotalAmount,currencyFormatPrecision) }}</div>
                                </v-col>
                                <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pb-0">
                                    <div class="font-weight-black">{{ $t('message.totalCommission') }}</div>
                                    <div class="font-weight-black">{{ formatThisNumber(itemsTotalCommission,currencyFormatPrecision) }}</div>
                                </v-col>
                            </template>
                        </v-row>
                    </div>
                </div>
                <div class="dense-inputs col-8 col-lg-9 col-md-9 pa-0 offset-4 offset-lg-3 offset-md-3" v-if="Contract__salestype_id == 2">
                    <v-row justify="end">
                        <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                            <v-text-field
                                :placeholder="$t('message.adjustment')"
                                :value="Contract__adjustment_title"
                                autocomplete="off"
                                class="custom py-0 mt-0 bg-lightgrey"
                                hide-details="auto"
                                @change="Contract__adjustment_title = $event"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                            <v-text-field
                                :value="Contract__adjustment"
                                autocomplete="off"
                                class="custom py-0 mt-0 bg-lightgrey force-text-left"
                                hide-details="auto"
                                type="number"
                                @change="Contract__adjustment = $event"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0"></v-col>
                    </v-row>
                </div>
                <div class="dense-inputs col-8 col-lg-9 col-md-9 pa-0 offset-4 offset-lg-3 offset-md-3" v-if="Contract__salestype_id == 2">
                    <v-row justify="end">
                        <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0 text-right font-weight-bold">
                            {{ $t('message.grandTotal') }}
                        </v-col>
                        <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0 font-weight-bold">
                            {{ formatThisNumber(itemsGrandTotal,currencyFormatPrecision) }}
                        </v-col>
                        <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0"></v-col>
                    </v-row>
                </div>
            </template>
        </v-data-table>
        <v-layout row>
            <v-flex xs12 lg6 md6 pl-3 py-1 class="align-center">
                {{ $t('message.specificationNotes') }}
                <v-lazy>
                    <v-textarea
                        :value="Contract__note"
                        auto-grow
                        class="cyan lighten-5 pt-3"
                        clearable
                        dense
                        hide-details="auto"
                        key="contract-note"
                        rows="1"
                        solo
                        @change="Contract__note = $event"
                    ></v-textarea>
                </v-lazy>
            </v-flex>
        </v-layout>
        <v-dialog
            overlay=false
            persistent
            scrollable
            transition="dialog-bottom-transition"
            v-model="specNotesDialog"
            v-if="false"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.specificationNotes') }}</v-card-title>
                <v-card-text class="px-6">
                    <v-textarea
                        :value="Contract__note"
                        class="elevation-0 pt-3"
                        dense
                        hide-details
                        outlined
                        rows="5"
                        @change="Contract__note = $event"
                    ></v-textarea>
                </v-card-text>
                <v-card-actions class="px-6">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="specNotesDialog = false"
                        class="ml-3"
                        color="default"
                        small
                    >{{ $t('message.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import { api } from "../../api";
    import { mapGetters, mapActions, mapMutations} from "vuex";
    import { mapFields } from "vuex-map-fields";
    import {generateDimension, generateSpec, isEmpty, numberFormat, log} from "../../helpers/helpers";
    // import debounce from 'lodash.debounce'
    import { mapFieldsPrefixed,mapMultiRowFieldsPrefixed } from "../../helpers/vuex-map-fields-prefixed";
    import ContractItemList from "Components/Appic/Loading/ContractItemList";

    export default {
        name: "SalesItemListNew",
        components: {ContractItemList},
        props: ['updateMode'],
        data(){
            return {
                dialogs: {
                    confirm:  false,
                    confirm_message: ""
                },
                commission: true,
                commUnitSuffixes: [],
                currentRowIndex: 0,
                fieldsWithApi: ['Salesitem.grade_id','Salesitem.size_id','Salesdescription.spec_id'],
                filteredSpecs: [],
                filteredSizes: [],
                filteredGrades: [],
                filteredThickness: [],
                filteredUnits: [],
                loadingGrades: [],
                loadingSizes: [],
                loadingSpecs: [],
                margin: true,
                maxNumberLength: 1000000,
                minNumberLength: 0,
                numberRules: [
                    v => v < this.maxNumberLength,
                    v => v > this.minNumberLength,
                ],
                resettingSalesitems: false,
                searchGrades: [],
                searchSizes: [],
                searchSpecs: [],
                specNotesDialog: false,
                updatingTotals: false
            }
        },
        computed: {
            statePrefix(){
                if(this.updateMode){
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('contract', {
                Contract__id: 'Contract.id',
                Contract__bank_id: 'Contract.bank_id',
                Contract__adjustment: 'Contract.adjustment',
                Contract__adjustment_title: 'Contract.adjustment_title',
                Contract__commission_by_unit: 'Contract.commission_by_unit',
                Contract__commission_per_line: 'Contract.commission_per_line',
                Contract__commission_unit_price: 'Contract.commission_unit_price',
                Contract__containercount: 'Contract.containercount',
                Contract__containersize_id: 'Contract.containersize_id',
                Contract__currency_id: 'Contract.currency_id',
                Contract__is_export: 'Contract.is_export',
                Contract__margin: 'Contract.margin',
                Contract__ms_id: 'Contract.ms_id',
                Contract__productgroup_id: 'Contract.productgroup_id',
                Contract__note: 'Contract.note',
                Contract__salestype_id: 'Contract.salestype_id',
                Contract__unit_commission: 'Contract.unit_commission',
            },'statePrefix'),
            ...mapFields('contract',{
                newContractSaved: 'newContractSaved',
                newContractUnsaved: 'newContractUnsaved',
                updatedContractSaved: 'updatedContractSaved',
                updatedContractUnsaved: 'updatedContractUnsaved',
            }),
            ...mapMultiRowFieldsPrefixed('salesitem',{
                Salesitems: 'Salesitems',
            },'statePrefix'),
            ...mapFieldsPrefixed('salesitem',{
                itemsGrandTotal: 'itemsGrandTotal',
                itemsTotalAmount: 'itemsTotalAmount',
                itemsTotalCommission: 'itemsTotalCommission',
                itemsTotalCost: 'itemsTotalCost',
                itemsTotalMargin: 'itemsTotalMargin',
                itemsTotalQty: 'itemsTotalQty',
                commissionByUnit: 'commissionByUnit',
            },'statePrefix'),
            ...mapGetters('certification', {
                allCertifications: 'allCertifications'
            }),
            ...mapGetters('currency', {
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('grade',{
                allGrades: 'allGrades',
                findGradeById: 'findGradeById'
            }),
            ...mapGetters('mc', {
                allMoistureContent: 'allMoistureContent'
            }),
            ...mapGetters('productgroup',{
                allProductGroups: 'allProductGroups'
            }),
            ...mapGetters('size',{
                allSizes: 'allSizes',
                findSizeById: 'findSizeById'
            }),
            ...mapGetters('spec',{
                allCleanedSpecs: 'allCleanedSpecs',
                allSpecs: 'allSpecs',
                findSpecById: 'findSpecById'
            }),
            ...mapGetters('species', {
                allSpecies: 'allSpecies',
                findSpeciesById: 'findSpeciesById'
            }),
            ...mapGetters('thickness', {
                allThickness: 'allThickness'
            }),
            ...mapGetters([
                // 'certificationsNew',
                'containerSizes',
                'currencies',
                // 'productGroupsNew',
                'uofmQty'
            ]),
            currencyFormatPrecision () {
                return this.allCurrencies.find((currency) => currency.Currency.id === this.Contract__currency_id)?.Currency?.formatPrecision
            },
            currencyPrecision () {
                return this.allCurrencies.find((currency) => currency.Currency.id === this.Contract__currency_id)?.Currency?.decimals
            },
            headers() {
                if([1,3,5,6].includes(this.Contract__salestype_id)) {
                    if(this.updateMode == false){
                        let headers = [
                            {
                                id: 0,
                                text: this.$t('message.lineNo'),
                                value: 'lineNumber',
                            },
                            {
                                id: 1,
                                text: this.$t('message.certification'),
                                value: 'Salesitem.certification_id',
                            },
                            {
                                id: 2,
                                text: this.$t('message.species'),
                                value: 'Salesdescription.species_id',
                            },
                            {
                                id: 3,
                                text: this.$t('message.description'),
                                value: 'Salesdescription.spec_id',
                            },
                            {
                                id: 4,
                                text: this.$t('message.size'),
                                value: 'Salesitem.size_id',
                            },
                            {
                                id: 5,
                                text: this.$t('message.quantity'),
                                value: 'Salesitem.itemqty',
                            },
                            {
                                id: 6,
                                text: this.$t('message.costPrice'),
                                value: 'Salesitem.costprice',
                            },
                            {
                                id: 7,
                                text: this.$t('message.salesPrice'),
                                value: 'Salesitem.unitprice',
                            },
                            {
                                id: 8,
                                text: this.$t('message.amount'),
                                value: 'Salesitem.amount',
                            },
                            {
                                id: 9,
                                text: this.$t('message.priceMargin'),
                                value: 'Salesitem.unitmargin',
                            },
                            {
                                id: 10,
                                text: this.$t('message.margin'),
                                value: 'Salesitem.margin',
                            },
                            {
                                id: 11,
                                text: '%',
                                value: 'marginPct',
                            },
                            {
                                id: 12,
                                text: this.$t('message.actions'),
                                value: 'index',
                                type: 'direct'
                            }
                        ]
                        return headers
                    } else {
                        let headers = [
                            {
                                id: 0,
                                text: this.$t('message.lineNo'),
                                value: 'lineNumber',
                            },
                            {
                                id: 1,
                                text: this.$t('message.certification'),
                                value: 'Salesitem.certification_id',
                            },
                            {
                                id: 2,
                                text: this.$t('message.species'),
                                value: 'Salesdescription.species_id',
                            },
                            {
                                id: 3,
                                text: this.$t('message.description'),
                                value: 'Salesdescription.spec_id',
                            },
                            {
                                id: 4,
                                text: this.$t('message.size'),
                                value: 'Salesitem.size_id',
                            },
                            {
                                id: 5,
                                text: this.$t('message.quantity'),
                                value: 'Salesitem.itemqty',
                            },
                            {
                                id: 6,
                                text: this.$t('message.costPrice'),
                                value: 'Salesitem.costprice',
                            },
                            {
                                id: 7,
                                text: this.$t('message.salesPrice'),
                                value: 'Salesitem.unitprice',
                            },
                            {
                                id: 8,
                                text: this.$t('message.amount'),
                                value: 'Salesitem.amount',
                            },
                            {
                                id: 9,
                                text: this.$t('message.poQuantity'),
                                value: 'Salesitem.poqty',
                                type: 'direct'
                            },
                            {
                                id: 10,
                                text: this.$t('message.priceMargin'),
                                value: 'Salesitem.unitmargin',
                            },
                            {
                                id: 11,
                                text: this.$t('message.margin'),
                                value: 'Salesitem.margin',
                            },
                            {
                                id: 12,
                                text: '%',
                                value: 'marginPct',
                            },
                            {
                                id: 13,
                                text: this.$t('message.actions'),
                                value: 'index',
                                type: 'direct'
                            }
                        ]
                        return headers
                    }
                } else {
                    let headers = [
                        {
                            id: 0,
                            text: this.$t('message.lineNo'),
                            value: 'lineNumber',
                        },
                        {
                            id: 1,
                            text: this.$t('message.certification'),
                            value: 'Salesitem.certification_id',
                        },
                        {
                            id: 1,
                            text: this.$t('message.species'),
                            value: 'Salesdescription.species_id',
                        },
                        {
                            id: 2,
                            text: this.$t('message.description'),
                            value: 'Salesdescription.spec_id',
                        },
                        {
                            id: 3,
                            text: this.$t('message.size'),
                            value: 'Salesitem.size_id',
                        },
                        {
                            id: 4,
                            text: this.$t('message.quantity'),
                            value: 'Salesitem.itemqty',
                        },
                        {
                            id: 5,
                            text: this.$t('message.unitPrice'),
                            value: 'Salesitem.unitprice',
                        },
                        {
                            id: 6,
                            text: this.$t('message.amount'),
                            value: 'Salesitem.amount',
                        }
                    ]
                    if (this.Contract__commission_by_unit == 0) {
                        headers.push(
                            {
                                id: 7,
                                text: this.$t('message.commPct'),
                                value: 'Salesitem.comm_pct',
                                width: '1%'
                            },
                        )
                        headers.push(
                            {
                                id: 8,
                                text: this.$t('message.comm'),
                                value: 'Salesitem.commission',
                                width: '1%'
                            },
                        )
                    }
                    if (this.Contract__commission_by_unit == 1) {
                        headers.push(
                            {
                                id: 7,
                                text: this.$t('message.commByUnit'),
                                value: 'Salesitem.comm_unit',
                                width: '1%'
                            },
                        )
                        headers.push(
                            {
                                id: 8,
                                text: this.$t('message.comm'),
                                value: 'Salesitem.commission',
                                width: '1%'
                            },
                        )
                    }
                    headers.push(
                        {
                            id: 9,
                            text: this.$t('message.actions'),
                            value: 'index',
                            type: 'direct'
                        }
                    )
                    //test
                    // headers.push({id: 10, text: '', value: ''});
                    // headers.push({id: 11, text: '', value: ''});
                    // headers.push({id: 12, text: '', value: ''});
                    return headers
                }
            },
            noSpecsFound(index) {
                if(this.searchSpecs[index] != null) {
                    return this.$t('message.noResultsMatching') + ' "' + '<strong>' + this.searchSpecs[index] + '</strong>".' + '  ' + this.$t('message.pressEnterToCreateNewOne')
                }
                return this.$t('message.startTypingToSearch');
            },
            someContractData() {
                let data = {
                    prefix: this.statePrefix,
                    contract: {
                        id: this.Contract__id,
                        salestype_id: this.Contract__salestype_id,
                        commission_per_line: this.Contract__commission_per_line,
                        comm_pct: this.Contract__commission_by_unit == 1 ? this.Contract__unit_commission : this.Contract__margin
                    }
                }
                return data
            }
        },
        methods: {
            ...mapActions('certification', {
                getAllCertifications: 'getAllCertifications'
            }),
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('grade', {
                getAllGrades: 'getAllGrades'
            }),
            ...mapActions('mc', {
                getAllMoistureContent: 'getAllMoistureContent'
            }),
            ...mapActions('productgroup', {
                getAllProductGroups: 'getAllProductGroups'
            }),
            ...mapActions('salesitem',{
                addOtherChargesItemRow: 'addOtherChargesItemRow',
                addSalesItemRow: 'addSalesItemRow',
                deleteSalesItem: 'deleteSalesItem',
                resetSalesItems: 'resetSalesItems',
                updateSalesItems: 'updateSalesItems',
                updateCommissionByUnit: 'updateCommissionByUnit',
                updateTotals: 'updateTotals'
            }),
            ...mapActions('size', {
                getAllSizes: 'getAllSizes'
            }),
            ...mapActions('spec', {
                getAllCleanedSpecs: 'getAllCleanedSpecs',
                getAllSpecs: 'getAllSpecs'
            }),
            ...mapActions('species', {
                getAllSpecies: 'getAllSpecies'
            }),
            ...mapActions('thickness', {
                getAllThickness: 'getAllThickness'
            }),
            ...mapMutations('salesitem',{
                addCreditSalesItemRow: 'addCreditSalesItemRow',
                deleteSalesItemRow: 'deleteSalesItemRow',
            }),
            commissionTypeChanged(value){
                let payload = {
                    prefix: this.statePrefix,
                    value: value
                }
                this.updateCommissionByUnit(payload)
                if(value == true && this.Contract__commission_per_line == 0){ //commission by unit
                    let value = this.Contract__unit_commission
                    this.Salesitems.forEach((item,index)=>{
                        item.Salesitem.comm_unit = value
                        this.updateSalesItemRowHandle(index,'Salesitem.comm_unit',value)
                    })
                    this.updateTotals({prefix: this.statePrefix, precision:  this.currencyPrecision})
                } else if(value == false && this.Contract__commission_per_line == 0){ //commission by percent
                    let value = this.Contract__margin
                    this.Salesitems.forEach((item,index)=>{
                        item.Salesitem.comm_pct = value
                        this.updateSalesItemRowHandle(index,'Salesitem.comm_pct',value)
                    })
                    this.updateTotals({prefix: this.statePrefix, precision:  this.currencyPrecision})
                }
            },
            async deleteItem(itemIndex,itemId) {
                let _this = this
                if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteItemAction'), {color: 'red'})) {
                    let promise = new Promise((resolve, reject) => {
                        let payload = {
                            index: itemIndex,
                            prefix: _this.statePrefix
                        }
                        this.deleteSalesItemRow(payload)
                        resolve('ok')
                    });
                    promise.then(()=>{
                        if(itemId != null){
                            this.deleteSalesItem({
                                    contract_id: this.Contract__id,
                                    salesitem_id: itemId}
                                )
                                .then((response)=>{
                                    _this.updateTotals({prefix: _this.statePrefix, precision:  _this.currencyPrecision})
                                })
                        } else {
                            _this.updateTotals({prefix: _this.statePrefix, precision: _this.currencyPrecision})
                        }
                    })
                    .finally(()=>{
                        _this.updateTotals({prefix: _this.statePrefix, precision: _this.currencyPrecision})
                    })
                }
            },
            doDebouncedGradesSearch (rowIndex, value) {
                let self = this
                clearTimeout(this._timerId)
                this._timerId = setTimeout(()=>{
                    this.doGradesSearch (rowIndex, value, self)
                },700)
            },
            doGradesSearch (rowIndex, value, self) {
                api.get("/grades", {
                    params: {
                        conditions: [
                            {
                                field: 'grades.title',
                                value: value,
                                textsearch: true
                            }
                        ]
                    }
                })
                .then(response => {
                    if (response.data.data.length > 0) {
                        self.filteredGrades[rowIndex] = response.data.data
                    } else {
                        self.filteredGrades[rowIndex] = []
                    }
                })
                .catch(error => {
                    self.error = self.$t('message.unknownError')
                    self.filteredGrades[rowIndex] = []
                })
            },
            doDebouncedSizesSearch (rowIndex, value) {
                clearTimeout(this._timerId)
                this._timerId = setTimeout(()=>{
                    this.doSizesSearch(rowIndex, value)
                },500)
            },
            doSizesSearch (rowIndex, value) {
                api.get("/sizes", {
                    params: {
                        conditions: [
                            {
                                field: 'sizes.title',
                                value: value,
                                textsearch: true
                            }
                        ]
                    }
                })
                .then(response => {
                    if (response.data.data.length > 0) {
                        this.filteredSizes[rowIndex] = response.data.data
                    } else {
                        this.filteredSizes[rowIndex] = []
                    }
                })
                .catch(error => {
                    this.error = this.$t('message.unknownError')
                    this.filteredSizes[rowIndex] = []
                })
            },
            doDebouncedSpecsSearch (rowIndex, value) {
                let self = this
                clearTimeout(this._timerId)
                this._timerId = setTimeout(()=>{
                    this.doSpecsSearch(rowIndex, value, self)
                },500)
            },
            doSpecsSearch: async (rowIndex, value, self) => {
                api.get("/specs", {
                    params: {
                        conditions: [
                            {
                                field: 'specs.title',
                                value: value,
                                textsearch: true
                            }
                        ]
                    }
                })
                .then(response => {
                    if (response.data.data.length > 0) {
                        self.filteredSpecs[rowIndex] = response.data.data
                    } else {
                        self.filteredSpecs[rowIndex] = []
                    }
                })
                .catch(error => {
                    self.error = self.$t('message.unknownError')
                    self.filteredSpecs[rowIndex] = []
                })
            },
            filterThickness(ms) {
                let various = this.allThickness.find(t => {
                    return t.Thickness.ms == ms && t.Thickness.title == 'various'
                })
                let thicknesses = this.allThickness.filter(t => {
                    return t.Thickness.ms == ms && t.Thickness.title != 'various'
                })
                thicknesses.sort((a,b) => {
                    let strA = a + ''
                    let strB = b + ''
                    return strA.localeCompare(strB, undefined, { numeric: true })
                })
                if(various){
                    this.filteredThickness = [various,...thicknesses]
                } else {
                    this.filteredThickness = thicknesses
                }
            },
            formatThisNumber (value,format) {
                return numberFormat(value,format)
            },
            getUiDescription(item) {
                let uiDescription = []

                const species = this.allSpecies.find(s => s.Species.id == item.Salesdescription.species_id)
                if(species) uiDescription.push(species.Species.title)

                if(item.Salesitem.description != null) {
                    const product = this.allProductGroups.find(p => p.ProductGroup.id == item.Salesitem.productgroup_id)
                    if (product && item.Salesitem.description.includes(product.ProductGroup.title) == false) uiDescription.push(product.ProductGroup.title)
                } else {
                    const product = this.allProductGroups.find(p => p.ProductGroup.id == item.Salesitem.productgroup_id)
                    if (product) uiDescription.push(product.ProductGroup.title)
                }

                if(item.Salesitem.description != '') uiDescription.push(item.Salesitem.description)

                const grade = this.allGrades.find(g => g.Grade.id == item.Salesitem.grade_id)
                if(grade) uiDescription.push(grade.Grade.title)

                let certification = this.allCertifications.find(c => c.Certification.id == item.Salesitem.certification_id)
                if(certification && item.Salesitem.description != null && item.Salesitem.description.includes(certification.Certification.title) == false && certification.Certification.title != 'None') uiDescription.push(certification.Certification.title)
                if(certification && item.Salesitem.description == null && certification.Certification.title != 'None') uiDescription.push(certification.Certification.title)

                let mc = this.allMoistureContent.find(mc => mc.Mc.id == item.Salesitem.mc_id)
                if(mc && item.Salesitem.description == null) uiDescription.push(mc.Mc.title)

                uiDescription = uiDescription.filter(Boolean).join(", ")
                return uiDescription
            },
            marginPct (sellingPrice, buyingPrice, importTransportPrice = 0) {
                if(this.Contract__salestype_id == 5){
                    var margin_pct = Math.round((parseFloat(sellingPrice) - (parseFloat(buyingPrice) + parseFloat(importTransportPrice))) / (parseFloat(sellingPrice) - parseFloat(importTransportPrice)) * 1000) / 10
                } else {
                    margin_pct = Math.round((parseFloat(sellingPrice) - (parseFloat(buyingPrice) + parseFloat(importTransportPrice))) / parseFloat(sellingPrice) * 1000) / 10
                }

                if(isFinite(margin_pct)){
                    return margin_pct
                } else {
                    return this.$t('message.na')
                }
            },
            async resetSpecifications () {
                if (await this.$root.$confirm(this.$t('message.resetSpecifications'), this.$t('message.confirmations.continueSpecificationsReset'), {color: 'orange'})) {
                    this.resettingSalesitems = true
                    this.Contract__margin = 0;
                    this.Contract__unit_commission = 0;
                    this.filteredSpecs = []; //reset
                    this.filteredSizes = []; //reset
                    this.filteredGrades = []; //reset
                    this.resetSalesItems(this.statePrefix).then(() => {
                        setTimeout(() => {
                            this.Salesitems.forEach((item, index) => {
                                this.updateSalesItemRowHandle(index, 'Grade.id', null)
                                this.updateSalesItemRowHandle(index, 'Grade.title', null)
                                this.updateSalesItemRowHandle(index, 'Salesdescription.species_id', null)
                                this.updateSalesItemRowHandle(index, 'Salesdescription.spec_id', null)
                                this.updateSalesItemRowHandle(index, 'Salesdescription.id', null)
                                this.updateSalesItemRowHandle(index, 'Salesdescription.title', null)
                                this.updateSalesItemRowHandle(index, 'Size.id', null)
                                this.updateSalesItemRowHandle(index, 'Size.title', null)
                                this.updateSalesItemRowHandle(index, 'Spec.id', null)
                                this.updateSalesItemRowHandle(index, 'Spec.title', null)
                                this.updateSalesItemRowHandle(index, 'Species.id', null)
                                this.updateSalesItemRowHandle(index, 'Species.title', null)
                            })
                            this.resettingSalesitems = false
                            this.$toast.success(this.$t('message.salesItemsResetNotification'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                        }, 100)
                    })
                }
            },
            setItemDescription(idx) {
                if(idx != null) {
                    let product = null
                    let spec = null
                    let mc = null

                    let productGroupId = this.Salesitems[idx].Salesitem.productgroup_id
                    let specId = this.Salesitems[idx].Salesdescription.spec_id
                    let mcId = this.Salesitems[idx].Salesitem.mc_id

                    let productObj = this.allProductGroups.find(pg => pg.ProductGroup?.id == productGroupId)
                    let specObj = this.allCleanedSpecs.find(s => s.Spec.id == specId)
                    if(specId == 'NEW'){
                        specObj = { Spec: this.Salesitems[idx]['Spec'] }
                    }
                    if(typeof specObj === 'undefined'){
                        specObj = { Spec: this.Salesitems[idx]['Spec'] }
                    }
                    let mcObj = this.allMoistureContent.find(mc => mc.Mc.id == mcId)

                    if(productObj) product = productObj?.Productgroup?.title
                    if(specObj) spec = specObj?.Spec?.title
                    if(mcObj) mc = mcObj?.Mc?.title

                    let description = generateSpec(product, spec, mc)
                    this.Salesitems[idx].Salesitem.description = description
                }
                return true
            },
            showUpdateButton(ref){
                this.$refs[ref].$el.setAttribute('style', 'display: block')
                return true
            },
            hideUpdateButton(ref){
                this.$refs[ref].$el.setAttribute('style', 'display: none')
                return true
            },
            uofmPrecision (uofm) {
                if(uofm) {
                    if(isNaN(uofm)) {
                        return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
                    } else {
                        return this.uofmQty.find((u) => u.Measurement.id == uofm)?.Measurement?.formatPrecision
                    }
                } else {
                    return '0,0.00'
                }
            },
            updateSalesItemRowHandle (idx,field,value) {
                const fieldsAffectingItemDescription = ['Salesitem.productgroup_id','Salesdescription.spec_id','Salesitem.grade_id','Salesitem.mc_id']
                const fieldsAffectingTotals = ['Salesitem.amount','Salesitem.comm_pct','Salesitem.comm_unit','Salesitem.costprice','Salesitem.itemqty','Salesitems.poqty','Salesitem.unitmargin','Salesitem.unitprice','Salesitem.import_transport_price']
                if(fieldsAffectingTotals.includes(field)) {
                    this.updatingTotals = true
                }
                if(this.fieldsWithApi.includes(field)){
                    switch(field) {
                        case 'Salesdescription.spec_id':
                            if(value) {
                                if(value?.Spec?.id) { //value is an object
                                    var payload = {
                                        index: idx,
                                        field: field,
                                        prefix: this.statePrefix,
                                        value: value?.Spec?.id
                                    }
                                    // assign Spec property in Salesdescription
                                    this.$store.commit('salesitem/updateSalesItems', {
                                        index: idx,
                                        field: 'Spec.id',
                                        prefix: this.statePrefix,
                                        value: value?.Spec?.id
                                    })
                                    this.$store.commit('salesitem/updateSalesItems', {
                                        index: idx,
                                        field: 'Spec.title',
                                        prefix: this.statePrefix,
                                        value: value?.Spec?.title
                                    })
                                } else { //value is a string - new spec
                                    var payload = {
                                        index: idx,
                                        field: field,
                                        prefix: this.statePrefix,
                                        value: 'NEW'
                                    }
                                    // assign Spec property in Salesdescription
                                    this.$store.commit('salesitem/updateSalesItems', {
                                        index: idx,
                                        field: 'Spec.id',
                                        prefix: this.statePrefix,
                                        value: 'NEW'
                                    })
                                    this.$store.commit('salesitem/updateSalesItems', {
                                        index: idx,
                                        field: 'Spec.title',
                                        prefix: this.statePrefix,
                                        value: value
                                    })
                                }
                            } else {
                                //if empty
                                var payload = {
                                    index: idx,
                                    field: field,
                                    prefix: this.statePrefix,
                                    value: null
                                }
                                // assign Spec property in Salesdescription
                                this.$store.commit('salesitem/updateSalesItems', {
                                    index: idx,
                                    field: 'Spec.id',
                                    prefix: this.statePrefix,
                                    value: null
                                })
                                this.$store.commit('salesitem/updateSalesItems', {
                                    index: idx,
                                    field: 'Spec.title',
                                    prefix: this.statePrefix,
                                    value: null
                                })
                            }
                            break
                        case 'Salesitem.size_id':
                            if(value) {
                                if(value?.Size?.id){ //value is an object
                                    var payload = {
                                        index: idx,
                                        field: field,
                                        prefix: this.statePrefix,
                                        value: value?.Size?.id
                                    }
                                    this.$store.commit('salesitem/updateSalesItems', {
                                        index: idx,
                                        field: 'Size.id',
                                        prefix: this.statePrefix,
                                        value: value?.Size?.id
                                    })
                                    this.$store.commit('salesitem/updateSalesItems', {
                                        index: idx,
                                        field: 'Size.title',
                                        prefix: this.statePrefix,
                                        value: value?.Size?.title
                                    })
                                } else { //value is a string - new size
                                    var payload = {
                                        index: idx,
                                        field: field,
                                        prefix: this.statePrefix,
                                        value: 'NEW'
                                    }
                                    this.$store.commit('salesitem/updateSalesItems', {
                                        index: idx,
                                        field: 'Size.id',
                                        prefix: this.statePrefix,
                                        value: 'NEW'
                                    })
                                    this.$store.commit('salesitem/updateSalesItems', {
                                        index: idx,
                                        field: 'Size.title',
                                        prefix: this.statePrefix,
                                        value: value
                                    })
                                }
                            } else {
                                //if empty
                                var payload = {
                                    index: idx,
                                    field: field,
                                    prefix: this.statePrefix,
                                    value: null
                                }
                                // assign Spec property in Salesdescription
                                this.$store.commit('salesitem/updateSalesItems', {
                                    index: idx,
                                    field: 'Size.id',
                                    prefix: this.statePrefix,
                                    value: null
                                })
                                this.$store.commit('salesitem/updateSalesItems', {
                                    index: idx,
                                    field: 'Size.title',
                                    prefix: this.statePrefix,
                                    value: null
                                })
                            }
                            // var payload = {
                            //     index: idx,
                            //     field: field,
                            //     prefix: this.statePrefix,
                            //     value: value?.Size?.id
                            // }
                            // // assign Size property in Salesitem
                            // this.$store.commit('salesitem/updateSalesItems', {
                            //     index: idx,
                            //     field: 'Size.id',
                            //     prefix: this.statePrefix,
                            //     value: value?.Size?.id
                            // })
                            // this.$store.commit('salesitem/updateSalesItems', {
                            //     index: idx,
                            //     field: 'Size.title',
                            //     prefix: this.statePrefix,
                            //     value: value?.Size?.title
                            // })
                            break;
                        case 'Salesitem.grade_id':
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value?.Grade?.id
                            }
                            // assign Grade property in Salesitem
                            this.$store.commit('salesitem/updateSalesItems', {
                                index: idx,
                                field: 'Grade.id',
                                prefix: this.statePrefix,
                                value: value?.Grade?.id
                            })
                            this.$store.commit('salesitem/updateSalesItems', {
                                index: idx,
                                field: 'Grade.title',
                                prefix: this.statePrefix,
                                value: value?.Grade?.title
                            })
                            break;
                    }
                } else {
                    switch(field) {
                        case 'Salesdescription.species_id':
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value,
                            }
                            //assign Species property in
                            let species = this.findSpeciesById(value);
                            this.$store.commit('salesitem/updateSalesItems', {
                                index: idx,
                                field: 'Species.id',
                                prefix: this.statePrefix,
                                value: species?.Species?.id ? species.Species.id : null
                            })
                            this.$store.commit('salesitem/updateSalesItems', {
                                index: idx,
                                field: 'Species.title',
                                prefix: this.statePrefix,
                                value: species?.Species?.title ? species.Species.title : null
                            })
                            break;
                        case 'Salesitem.measurement_id':
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value,
                            }
                            //assign Measurement property in
                            let measurement = this.uofmQty.filter(uofm => uofm.Measurement.id === value)[0];
                            if(measurement != null) {
                                this.$store.commit('salesitem/updateSalesItems', {
                                    index: idx,
                                    field: 'Measurement.id',
                                    prefix: this.statePrefix,
                                    value: measurement.Measurement.id != null ? measurement.Measurement.id : null
                                })
                                this.$store.commit('salesitem/updateSalesItems', {
                                    index: idx,
                                    field: 'Measurement.title',
                                    prefix: this.statePrefix,
                                    value: measurement.Measurement.title != null ? measurement.Measurement.title : null
                                })
                            }
                            break;
                        case 'Salesitem.thickness_id':
                        case 'Salesitem.width':
                        case 'Salesitem.length':
                            //update size_text
                            let thicknessId = this.Salesitems[idx].Salesitem.thickness_id
                            let width = this.Salesitems[idx].Salesitem.width
                            let length = this.Salesitems[idx].Salesitem.length
                            if(field == 'Salesitem.thickness_id'){
                                thicknessId = value
                            }
                            if(field == 'Salesitem.width'){
                                width = value
                            }
                            if(field == 'Salesitem.length'){
                                length = value
                            }

                            let thicknessObj = this.allThickness.find(t => t.Thickness.id == thicknessId)
                            let thickness = thicknessObj?.Thickness.title

                            let sizeText = generateDimension(thickness,width,length)

                            this.$store.commit('salesitem/updateSalesItems', {
                                index: idx,
                                field: 'Salesitem.size',
                                prefix: this.statePrefix,
                                value: sizeText
                            })

                            //update Size object
                            //check if size exists
                            let sizeId = null
                            let sizeObj = this.allSizes.find(s => s.Size.title == sizeText)
                            if(sizeObj){
                                sizeId = sizeObj?.Size.id
                            } else {
                                if (sizeText.length > 0) {
                                    sizeId = 'NEW' //new size object
                                }
                            }
                            this.$store.commit('salesitem/updateSalesItems', {
                                index: idx,
                                field: 'Size.id',
                                prefix: this.statePrefix,
                                value: sizeId
                            })
                            this.$store.commit('salesitem/updateSalesItems', {
                                index: idx,
                                field: 'Size.title',
                                prefix: this.statePrefix,
                                value: sizeText
                            })
                            this.$store.commit('salesitem/updateSalesItems', {
                                index: idx,
                                field: 'Salesitem.size_id',
                                prefix: this.statePrefix,
                                value: sizeId
                            })

                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value,
                            }
                            break
                        default:
                            if(typeof value === 'undefined') value = null
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value,
                            }
                    }
                }
                const promise = new Promise((resolve, reject) => {
                    try {
                        this.$store.commit('salesitem/updateSalesItems', payload)
                        resolve('ok')
                    } catch(error) {
                        reject(error)
                    }
                });
                promise
                    .then(() => {
                        if(fieldsAffectingItemDescription.includes(field)){
                            this.setItemDescription(idx)
                        }
                        if(fieldsAffectingTotals.includes(field)) {
                            const updateTotalPromise = new Promise((resolve, reject) => {
                                try {
                                    this.updateTotals({
                                        prefix: this.statePrefix,
                                        precision: this.currencyPrecision
                                    })
                                    resolve('ok')
                                } catch(error){
                                    reject(error)
                                }
                            })
                            updateTotalPromise
                                .then(()=>{
                                    const _this = this
                                    setTimeout(()=>{
                                        _this.updatingTotals = false
                                    },500)
                                })
                                .catch((error) => {
                                    this.$toast.error(error,
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                    this.updatingTotals = false
                                })
                        }
                    })
                    .catch((error) => {
                        this.$toast.error(error,
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.updatingTotals = false
                    })
            },
            validateWhenFocused(item) {
                this.maxNumberLength = item.maxValue
                this.minNumberLength = item.minValue
            }
        },
        watch: {
            Contract__adjustment (value) {
                this.itemsGrandTotal = parseFloat(this.itemsTotalAmount) + parseFloat(value)
            },
            Contract__ms_id(value){
                // this.filteredUnits = this.uofmQty.filter(u => {
                //     return u.Measurement.system == value || u.Measurement.system == null
                // })
                // this.filteredThickness = this.allThickness.filter(t => {
                //     return t.Thickness.ms == value
                // })
                this.filterThickness(value)
            },
            Contract__margin(value){
                if(this.Contract__commission_per_line == 0){
                    this.Salesitems.forEach((item,index)=>{
                        item.Salesitem.comm_pct = value
                        this.updateSalesItemRowHandle(index,'Salesitem.comm_pct',value)
                    })
                }
            },
            Contract__commission_per_line(value){
                if(value == 0 && this.Contract__commission_by_unit == 0){
                    this.Salesitems.forEach((item)=>{
                        if(item.type == 'default') {
                            item.Salesitem.comm_pct = this.Contract__margin
                            //update commission per item
                            this.$store.commit('salesitem/updateSalesItems', {
                                index: index,
                                field: 'Salesitem.comm_pct',
                                value: value
                            })
                        }
                    })
                    this.updateTotals({prefix: this.statePrefix, precision: this.currencyPrecision});
                } else if(value == 0 && this.Contract__commission_by_unit == 1){
                    this.Salesitems.forEach((item)=>{
                        item.Salesitem.comm_unit = this.Contract__unit_commission
                        //update commission per item
                        this.$store.commit('salesitem/updateSalesItems', {
                            index: index,
                            field: 'Salesitem.comm_unit',
                            value: value
                        })
                    })
                    this.updateTotals({prefix: this.statePrefix, precision:  this.currencyPrecision});
                }
            },
            Contract__unit_commission(value){
                if(this.Contract__commission_per_line == 0){
                    this.Salesitems.forEach((item,index)=>{
                        item.Salesitem.comm_unit = value
                        this.updateSalesItemRowHandle(index,'Salesitem.comm_unit',value)
                    })
                }
            },
            itemsTotalAmount (value) {
                this.itemsGrandTotal = parseFloat(value) + parseFloat(this.Contract__adjustment)
            },
            searchGrades(value){
                if(value[this.currentRowIndex] == null ||  value[this.currentRowIndex].length == ''){
                    return
                }
                this.doDebouncedGradesSearch(this.currentRowIndex, value[this.currentRowIndex], this)
            },
            searchSizes(value){
                if(value[this.currentRowIndex] == null ||  value[this.currentRowIndex].length == ''){
                    return
                }
                this.doDebouncedSizesSearch(this.currentRowIndex, value[this.currentRowIndex], this)
            },
            searchSpecs(value){
                if(value[this.currentRowIndex] == null ||  value[this.currentRowIndex].length == ''){
                    return
                }
                this.doDebouncedSpecsSearch(this.currentRowIndex, value[this.currentRowIndex], this)
            }
        },
        mounted(){
            // initialize autocomplete fields that use API

            this.filteredGrades = []
            // this.filteredSizes = []
            this.filteredSpecs = []
            this.searchGrades = []
            this.searchSizes = []
            this.searchSpecs = []

            if(this.allCleanedSpecs.length == 0) this.getAllCleanedSpecs()
            if(this.allGrades.length == 0) this.getAllGrades()
            if(this.allMoistureContent.length == 0) this.getAllMoistureContent()
            if(this.allProductGroups.length == 0) this.getAllProductGroups()
            if(this.allSizes.length == 0) this.getAllSizes()
            if(this.allSpecs.length == 0) this.getAllSpecs()
            if(this.allSpecies.length == 0) this.getAllSpecies()
            if(this.allThickness.length == 0) this.getAllThickness()
            if(this.allCertifications.length == 0) this.getAllCertifications()
            if(this.allCurrencies.length == 0) this.getAllCurrencies()

            // var _this = this
            // this.Salesitems.forEach(function(item) {
            //     var arrayGrade = []
            //     if(item.Grade.id != null) {
            //         var obj = {'Grade':{}}
            //         obj['Grade']['id'] = item.Grade.id
            //         obj['Grade']['title'] = item.Grade.title
            //         arrayGrade.push(obj)
            //     }
            //     _this.filteredGrades.push(arrayGrade)
            //
            //     var arraySize = []
            //     if(item.Size.id != null){
            //         var obj = {'Size':{}}
            //         obj['Size']['id'] = item.Size.id
            //         obj['Size']['title'] = item.Size.title
            //         arraySize.push(obj)
            //     }
            //     _this.filteredSizes.push(arraySize)
            //
            //     var arraySpec = []
            //     if(item.Spec.id != null){
            //         var obj = {'Spec':{}}
            //         obj['Spec']['id'] = item.Spec.id
            //         obj['Spec']['title'] = item.Spec.title
            //         arraySpec.push(obj)
            //     }
            //     _this.filteredSpecs.push(arraySpec)
            //
            //     _this.searchGrades.push('')
            //     _this.searchSizes.push('')
            //     _this.searchSpecs.push('')
            //     _this.loadingGrades.push(false)
            //     _this.loadingSizes.push(false)
            //     _this.loadingSpecs.push(false)
            // });

            // this.filteredUnits = this.uofmQty.filter(u => {
            //     return u.Measurement.system == this.Contract__ms_id || u.Measurement.system == null
            // })
            // this.filteredThickness = this.allThickness.filter(t => {
            //     return t.Thickness.ms == this.Contract__ms_id
            // })

            this.filterThickness(this.Contract__ms_id)

            //this is a guarantee that the commission_by_unit var is updated
            let commissionType = this.Contract__commission_by_unit
            this.commissionTypeChanged(commissionType)

            this.$store.watch(
                function(state) {
                    return state.appic.salesitem.update.Salesitems
                },
                () => {
                    this.updatedContractUnsaved = true
                },
                { deep: true }
            )

            this.$store.watch(
                function(state) {
                    return state.appic.salesitem.current.Salesitems
                },
                () => {
                    this.newContractUnsaved = true
                },
                { deep: true }
            )
        }
    }
</script>

<style lang="scss">
    @import "vue-select/src/scss/vue-select.scss";
</style>

<style>
.border-red {
    border-color: #FF0000;
    border-width: 1px;
    border-style: solid;
}
.font-16px {
    font-size: 16px !important;
    line-height: 20px;
}
.bg-lightgrey {
    background-color: #F5F5F5;
}
.force-text-left {
    text-align: left !important;
}
.v-text-field__slot input {
    text-align: right;
}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
table td.text-start {
    padding-left: 3px !important;
    padding-right: 0px !important;
}
.v-data-table tbody td {
    vertical-align: top !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 40px !important;
}
.v-input__append-inner {
    margin-left: 0px !important;
}
.width-18-pct {
    width: 18%;
}
.width-8-pct {
    width: 8%;
}
/*.specification-table table {*/
/*    table-layout: fixed;*/
/*}*/
</style>